import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const formSlice = createSlice({
    name: 'form',
    initialState: {
        formName: '',
        data: {},
        valuation: {},
        stampDuty: null,
        mortgage: null,
        isFormSubmitting: false,
    },
    reducers: {
        updateFormState: (state, action) => {
            //console.log('REDUX: UPDATE FORM STATE', state, action)
            const { formName, data } = action.payload;
            if(data) { 
                state.data = data;
                state.formName = formName;
            }
        },
        updateMortgageValuation: (state, action) => {
            const { formName, data } = action.payload;
            if(data) {
                state.mortgage = data.mortgage;
                state.formName = formName;
            }
        },
        updateStampeDutyValuation: (state, action) => {
            const { formName, data } = action.payload;
            if(data) {
                state.stampDuty = data?.stampDuty;
                state.formName = formName;
            }

        },
        updateValuationState: (state, action ) => {
            //console.log('REDUX: UPDATE ACTION STATE', state, action)
            state.valuation = action.payload
        },
        isFormSubmitting: (state, action) => {
            // console.log('isform submitting', action, action.payload);
            state.isFormSubmitting = action.payload
        },
    }

});

export const { updateFormState, updateValuationState, updateMortgageValuation, updateStampeDutyValuation,isFormSubmitting } = formSlice.actions
export default formSlice.reducer;

import React from "react"
import { Script } from "gatsby"

export const wrapPageElement = ({ element }) => {
  return (
    <>
        {element}
        <Script 
            key="script-id"  
            strategy="idle"
            onLoad={() => console.log("SCRIPT LOADED")}
            onError={() => console.log("SCRIPT FAILED TO LOAD")}>
            {
            `
            console.log('common scripts');
            `
            }
        </Script>
    </>
  )
}

// web font


// wordpress admin css
// normalize CSS across browsers
import "./src/css/@wordpress/load-styles.css"
import "./src/css/@wordpress/block-library/build-style/style.css"
import "./src/css/normalize.css"

//Custom CSS
import "../assets/css/style.css"

//Global CSS of wordpress.
import "./src/css/gutenberg-global.css"
import "../theme-options-style.css"

//lazyload
import "lazysizes"

//custom js
import "./static/custom.js"
import React from "react"

import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider

export { wrapPageElement } from "./gatsby-shared"


import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const modals = createSlice({
    name: 'modals',
    initialState : {
        personalizeFormShown: false
    },
    reducers: {
        togglePersonalize: (state, action) => {
            state.personalizeFormShown =  action.payload;
        }
    }
})

export const { togglePersonalize } = modals.actions

export default modals.reducer;

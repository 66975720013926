
import { configureStore,combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import user from "../redux/slice/user"
import branch from "../redux/slice/branch"
import form from "../redux/slice/form"
import modals from "../redux/slice/modals";
import common from '../redux/slice/common';
import propertySearch from "../redux/slice/property-search"

const reducers = combineReducers({
	user: user,
	branch: branch,
	form: form,
	modals: modals,
	common : common,
	propertySearch: propertySearch,
});

const persistConfig = {
	key: 'signatureRoot',
	storage,
	timeout: null,
	blacklist: ['form', 'propertySearch']
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
	reducer: persistedReducer,
});

export default store;